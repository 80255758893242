.media-selector {
    .card-body {
        .image-container {
            height: 15vh;

            display: flex;
            justify-content: center;
            align-items: center;

            cursor: pointer;

            &:hover {
                img {
                    opacity: 0.75;
                }
            }

            img {
                max-width: 100%;
                max-height: 100%;
                transition: opacity 120ms ease;
            }
        }
    }
}

.media-library-popup {
    .media-list {
        display: flex;
        flex-wrap: wrap;

        .media-icon {
            width: calc(100% / 6 - 40px);
            margin: 20px;

            position: relative;

            &::before {
                content: "";
                display: block;
                padding-bottom: 100%;
            }

            &-wrapper {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;

                overflow: hidden;

                display: flex;
                justify-content: center;
                align-items: center;

                &-image {
                    max-height: 100%;
                }
            }

            &.selected {
                border: 2px solid $primary;
                border-radius: 5px;
            }
        }
    }
}
