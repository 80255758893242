.modal {
    height: 100vh;
    display: flex !important;
    justify-content: center;
    align-items: center;

    .modal-dialog {
        width: 500px;
    }
}

.modal .modal-dialog {
    &.modal-lg {
        width: 800px;
    }
    &.modal-xl {
        width: 1140px;
    }
    &.modal-xxl {
        width: 96vw;
        max-width: 96vw;
    }
}
