.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100; /* Behind the navbar */
    padding: 48px 0 0; /* Height of navbar */
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);

    .nav-link {
        font-weight: 500;
        font-size: 0.875rem;
        color: $dark;

        &.active {
            color: #007bff;
        }

        &:hover,
        &.active {
            i.fas {
                color: inherit;
            }
        }

        i.fas {
            width: 30px;
            color: $gray;
            transition: color 120ms linear;
        }
    }

    &-heading {
        font-size: 0.75rem;
        text-transform: uppercase;
    }

    &-sticky {
        position: sticky;
        top: 0;
        height: calc(100vh - 48px);
        padding-top: 0.5rem;
        overflow-x: hidden;
        overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
    }
}

main {
    padding-top: 78px; /* Space for fixed navbar */
}

.navbar {
    .form-control {
        padding: 0.75rem 1rem;
        border-width: 0;
        border-radius: 0;

        &-dark {
            color: #fff;
            background-color: rgba(255, 255, 255, 0.1);
            border-color: rgba(255, 255, 255, 0.1);

            &:focus {
                border-color: transparent;
                box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.25);
            }
        }
    }

    &-brand {
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        font-size: 1rem;
        background-color: rgba(0, 0, 0, 0.25);
        box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.25);
    }
}

.icon-select {
    margin-bottom: 1rem;

    .input {
        display: flex;

        height: 37px;

        .preview {
            height: 100%;
            background-color: $light;
            border-radius: 5px;
            padding: 0 5px;
            margin-right: 20px;

            display: flex;
            justify-content: center;
            align-items: center;

            i {
                font-size: 30px;
                color: $dark;
            }
        }

        .select-button {
            flex: 1;
        }
    }
}

.modal-icon-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    max-height: 60vh;
    overflow-y: auto;

    i {
        font-size: 40px;
        cursor: pointer;
        transition: color 80ms ease;
        margin: 0 40px 20px 0;

        display: block;
        width: calc(100% / 8 - 40px);
        height: 30px;
        text-align: center;

        &:hover {
            color: $primary;
        }
    }
}
