#quick-search-container {
    position: relative;

    #qs-option-list {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;

        padding: 10px 5px;

        background-color: lighten($color: $dark, $amount: 10%);

        .qs-option-list--item {
            color: $white;
            background-color: lighten($color: $dark, $amount: 3%);
            padding: 5px 10px;
            margin-bottom: 5px;
            display: block;
            width: 100%;

            transition: background-color 80ms ease-out;

            &:last-child {
                margin-bottom: 0;
            }

            &:hover {
                text-decoration: none !important;
                background-color: lighten($color: $dark, $amount: 6%);
            }
        }
    }
}
