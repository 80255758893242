#contentTable {
    thead {
        th {
            border-top: none;
        }
    }

    tbody {
        tr[data-resource-id] {
            cursor: pointer;

            &.selected {
                td,
                th {
                    background-color: $primary;
                    color: $white;
                }
            }
        }
    }
}
